import styles from './TransformedText.module.css';

export const TransformedText = ({ items }) => (
  <div className={styles.content__container}>
    <ul className={styles.content__container__list}>
      {items?.map((text, index) => (
        <li
          key={`tt-${index}`}
          className={`${styles.content__container__list__item} flex justify-center`}
        >
          {text}
        </li>
      ))}
    </ul>
  </div>
);
