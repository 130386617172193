import {
  COMPANY_ADDR,
  COMPANY_NIP,
  FACEBOOK_URL,
  INSTAGRAM_URL,
} from 'shared/constants';
import { Button } from 'shared/ui/Button';
import { Link } from 'shared/ui/Link';
import { emailPattern } from 'shared/validators';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNewsletter } from '../../../services/useNewsletter';
import { EmailInput } from '../../forms/EmailInput';
import { Field } from '../../forms/Field';

function NewsletterForm({ source }) {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { register, errors, handleSubmit, reset, setError } = useForm();
  const { newsletterSubscribe } = useNewsletter();

  const onSubmit = async ({ email }) => {
    setLoading(true);
    try {
      await newsletterSubscribe(email, source);
      setSubmitted(true);
      reset();
    } catch (error) {
      if (error.errors) {
        for (const [name, message] of Object.entries(error.errors)) {
          setError(name, { type: 'server', message });
        }
      } else {
        alert(`Server error: ${error}`);
      }

      setSubmitted(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form
        className="mt-4 sm:flex sm:max-w-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Field
          as={EmailInput}
          name="email"
          id="email"
          ref={register({ required: true, pattern: emailPattern })}
          errors={errors}
          className="appearance-none min-w-0 w-full h-12 bg-white border border-transparent rounded-lg py-3 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-300 focus:ring-white focus:border-white focus:placeholder-gray-400"
          placeholder="Podaj swój e-mail"
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <Button
            type="submit"
            loading={loading}
            fullWidth
            color="primaryLight"
            variant="contained"
            size="large"
            className="max-h-12"
            // original btn classes
            // className="w-full bg-orange-500 border border-transparent rounded-lg py-3 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-300 focus:ring-orange-500"
          >
            Zapisz mnie
          </Button>
        </div>
      </form>

      {submitted && (
        <div className="text-green-500 mt-2 text-base">Dziękujemy!</div>
      )}

      <p className="mt-3 text-xs leading-6 text-gray-400">
        Podając swój adres e-mail, akceptujesz{' '}
        <Link href="/terms" className="hover:text-gray-900 font-medium">
          Regulamin
        </Link>{' '}
        oraz{' '}
        <Link href="/privacy" className="hover:text-gray-900 font-medium">
          Politykę Prywatności
        </Link>
        .
      </p>
    </>
  );
}

export const FooterV2 = () => {
  return (
    <footer style={{ backgroundColor: '#E7E3DA' }}>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Produkty
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      href="/choose"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Nasze boxy
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/products"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Nasze produkty
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/reviews"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Opinie klientów
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Wojna Warzyw
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      href="/about"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      O nas
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/delivery"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Dostawa i płatność
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://form.typeform.com/to/Qfk4izvJ"
                      external
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Dla rolników
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Pomoc
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      href="/faq"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Pytania i odpowiedzi
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/contact"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Centrum pomocy
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/refunds"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Zwroty i reklamacje
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Kontakt
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link
                      href="/contact"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Kontakt
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/contact"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Kariera
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/contact"
                      className="text-base text-gray-900 font-medium hover:text-black"
                    >
                      Media i prasa
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              Zapisz się do naszego newslettera
            </h3>
            <p className="mt-4 text-base text-gray-900 font-medium">
              Zarejestruj się i bądź na bieżąco z nowościami i okazjami
            </p>
            <NewsletterForm source="FOOTER_EMAIL_SIGNUP" />
          </div>
        </div>
        <div className="mt-8 border-t border-gray-300 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            <Link
              href={FACEBOOK_URL}
              className="text-gray-600 hover:text-gray-900 font-medium"
            >
              <span className="sr-only">Facebook</span>
              <svg
                className="h-8 w-8"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
            <Link
              href={INSTAGRAM_URL}
              className="text-gray-600 hover:text-gray-900 font-medium"
            >
              <span className="sr-only">Instagram</span>
              <svg
                className="h-8 w-8"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          </div>
          <p className="mt-8 text-xs text-gray-400 md:mt-0 md:order-1">
            &copy; Wojna Warzyw. Wszelkie prawa zastrzeżone. 7ninjas Sp. z o.o.
            z siedzibą w Rzeszowie przy ul. {COMPANY_ADDR}, NIP: {COMPANY_NIP}
            <br />
            <br />
            <Link
              href="/terms"
              className="mr-4 text-gray-400 hover:text-gray-900 font-medium"
            >
              Regulamin
            </Link>
            <Link
              href="/privacy"
              className="text-gray-400 hover:text-gray-900 font-medium"
            >
              Polityka prywatności
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};
