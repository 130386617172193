export const isSecondPhone = () => {
  const now = new Date();
  const isWeekend = now.getDay() % 6 == 0;
  const isAfter21 = now.getHours() >= 21;
  const isAfter18 = now.getHours() >= 18;
  const isBefore9 = now.getHours() < 9;

  return (
    (isWeekend && !isBefore9 && !isAfter21) ||
    (!isWeekend && isAfter18 && !isAfter21)
  );
};
