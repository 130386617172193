// import { getFooterVersion } from '../../client/experiments';
// import { Footer } from './Footer/Footer';
import { FooterV2 } from './Footer/FooterV2';

export function DefaultFooter() {
  // const footerVersion = useExperiment(getFooterVersion, 'v2');

  // if (footerVersion === 'v2') {
  //   return <FooterV2 />;
  // }

  return <FooterV2 />;
}
