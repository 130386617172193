import { forwardRef } from 'react';
import { ErrorMessage } from './ErrorMessage';
import { HintMessage } from './HintMessage';
import { Input } from './Input';

export function getErrorMessage(error) {
  if (error?.message) {
    return error.message;
  }

  if (error.type === 'required') {
    return 'To pole jest wymagane.';
  }

  return 'Błąd';
}

export const Field = forwardRef(
  (
    {
      label,
      helperText,
      as: Component = Input,
      name,
      errors,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const tmpName = name.split('.');
    const error =
      tmpName.length === 1
        ? errors[name]
        : !!errors[tmpName[0]] && errors[tmpName[0]][tmpName[1]];

    const isInvalid = !!error;

    return (
      <div className="flex-grow ">
        {/* {label && (
          <Label className="mb-1" isInvalid={isInvalid} htmlFor={name}>
            {label}
          </Label>
        )} */}
        <div className="relative rounded-md shadow-sm">
          <Component
            id={name}
            name={name}
            label={label}
            ref={ref}
            className={className}
            isInvalid={isInvalid}
            {...props}
          >
            {children}
          </Component>
        </div>
        {isInvalid && <ErrorMessage>{getErrorMessage(error)}</ErrorMessage>}
        {helperText && <HintMessage>{helperText}</HintMessage>}
      </div>
    );
  }
);
