import { INFO_TEL, INFO_TEL_18_WEEK, MESSENGER_URL } from 'shared/constants';
import { Button } from 'shared/ui/Button';
import { Link } from 'shared/ui/Link';
import { TransformedText } from 'shared/ui/TransformedText';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import {
  getHeadBgVersion,
  getOrderUnder80Flow,
  getSmallBoxMinOrderFlow,
  useExperiment,
} from '../client/experiments';
import { isSecondPhone } from '../services/utils';
import { FreeShippingHeaderItem, postHeaderItems } from './PostHeader';

const DeliveryAndPaymentPopup = dynamic(
  () => import('./static/DeliveryAndPaymentPopup'),
  {
    ssr: false,
  }
);

const LinkBody = ({ version, children }) => (
  <div
    className={clsx(
      'text-base leading-6 font-medium border-b hover:border-gray-50',
      version === 'v1' ? 'border-green-400 ' : 'border-gray-50'
    )}
  >
    {children}
  </div>
);

export function PreHeader() {
  const [showDeliveryPopup, setShowDeliveryPopup] = useState(false);
  const smallBoxMinOrderFlow = useExperiment(getSmallBoxMinOrderFlow, 'v1');
  const headBgVersion = useExperiment(getHeadBgVersion, 'v1');
  const orderUnder80Flow = useExperiment(getOrderUnder80Flow, 'v1');
  const now = new Date();
  const isAfter21 = now.getHours() >= 21;
  const isBefore9 = now.getHours() < 9;

  const urlPhone = `tel:${(isSecondPhone()
    ? INFO_TEL_18_WEEK
    : INFO_TEL
  ).replace(/\s/g, '')}`;
  const phone = (isSecondPhone() ? INFO_TEL_18_WEEK : INFO_TEL).replace(
    '+48 ',
    ''
  );

  const showPhone = !isAfter21 && !isBefore9;

  return (
    <>
      {showDeliveryPopup && (
        <DeliveryAndPaymentPopup onClose={() => setShowDeliveryPopup(false)} />
      )}
      <section
        className={clsx(
          'h-12 flex items-center',
          headBgVersion === 'v1'
            ? 'bg-green-200 text-green-700'
            : 'bg-mongo text-gray-50 bg-left-bottom bg-repeat-x bg-cover'
        )}
      >
        <div className="w-full max-w-7xl mx-auto flex items-center space-x-3 px-4 sm:px-6 sm:grid sm:grid-cols-3 sm:space-x-0">
          <div className={smallBoxMinOrderFlow === 'v1' ? 'md:flex-grow' : ''}>
            <div className="flex flex-row">
              <Link
                onClick={() => setShowDeliveryPopup(true)}
                className="hover:text-gray-50 transition"
              >
                <LinkBody version={headBgVersion}>
                  Dostawa{' '}
                  <span className="hidden md:inline-block">i płatność</span>
                </LinkBody>
              </Link>
            </div>
          </div>

          {smallBoxMinOrderFlow === 'v2' ? (
            <div className="hidden md:flex flex-grow justify-center items-center">
              {orderUnder80Flow === 'v2' ? (
                <FreeShippingHeaderItem />
              ) : (
                <TransformedText items={postHeaderItems} />
              )}
            </div>
          ) : (
            <div className="hidden md:flex"></div>
          )}

          <div className="flex flex-row justify-between sm:justify-end items-center space-x-3 sm:space-x-6 flex-grow sm:flex-grow-0">
            <div className="flex-grow md:flex-none">
              <div className="flex flex-row">
                <Link href="/contact" className="hover:text-gray-50 transition">
                  <div className="text-base leading-6 font-medium border-b border-green-400 hover:border-gray-50">
                    Pomoc{' '}
                    <span className="hidden md:inline-block">i kontakt</span>
                  </div>
                </Link>
              </div>
            </div>
            <div>
              {showPhone ? (
                <Link
                  href={urlPhone}
                  className="hover:text-gray-50 transition flex flex-row flex-nowrap"
                >
                  <div className="flex flex-row flex-nowrap">
                    <svg
                      viewBox="0 0 32 32"
                      className="h-6 w-6 mr-2"
                      stroke="currentColor"
                    >
                      <path
                        d="M13.539 31c-.677-.21-1.362-.398-2.03-.636a1.962 1.962 0 0 1-1.305-1.982c.053-.863.674-1.622 1.55-1.792.34-.066.723-.032 1.065.048.568.133 1.122.329 1.675.519.398.136.728.388.943.751.123.208.283.267.5.282 1.558.101 3.025-.245 4.421-.91 2.265-1.08 3.826-2.843 4.896-5.078-.236-.174-.476-.327-.685-.511-.836-.734-1.292-1.657-1.307-2.772-.017-1.26-.034-2.52.003-3.779.044-1.443.754-2.513 1.998-3.227.17-.097.226-.171.19-.378-.647-3.865-3.831-7.05-7.718-7.599-4.1-.577-7.357.848-9.665 4.314-.69 1.034-1.08 2.2-1.275 3.43-.03.18.02.271.173.373 1.147.76 1.756 1.83 1.771 3.213.013 1.202.01 2.402.001 3.603-.015 1.935-1.56 3.603-3.461 3.754-2.074.165-3.865-1.16-4.219-3.12-.014-.074-.04-.148-.06-.222v-4.453c.015-.036.037-.07.044-.107.29-1.587 1.208-2.626 2.74-3.12.162-.052.192-.147.214-.295.773-4.965 4.613-9.01 9.515-10.017.589-.12 1.189-.194 1.784-.289h1.698c.453.066.908.113 1.356.2 5.23 1 9.05 4.901 9.927 10.145.035.215.123.304.315.382 1.144.469 1.9 1.3 2.258 2.486.056.184.1.371.149.557v4.51c-.02.074-.047.147-.06.221-.294 1.649-1.743 2.993-3.396 3.113-.273.02-.378.13-.476.36-1.971 4.676-6.4 7.437-11.395 7.118-.086-.006-.214.015-.256.072-.315.427-.74.68-1.234.836h-.644z"
                        fill="currentColor"
                        fillRule="evenodd"
                      ></path>
                    </svg>

                    <div className="text-base leading-6 font-bold border-b border-green-400 hover:border-gray-50">
                      {phone}
                    </div>
                  </div>
                </Link>
              ) : (
                <Button
                  color="secondaryLight"
                  size="small"
                  variant="contained"
                  href={MESSENGER_URL}
                  external={true}
                  className="pr-2 hover:bg-green-300"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="fill-current h-5 w-5 mr-1"
                  >
                    <g strokeWidth={1} fillRule="evenodd">
                      <rect id="bounding-box" fillOpacity={0} x={0} y={0} />
                      <g id="logo">
                        <path
                          d="M512,122 C286.668,122 112,287.056 112,510 C112,626.6144 159.792,727.3824 237.6224,796.984 C244.156,802.832 248.1,811.024 248.368,819.792 L250.5464,890.944 C251.2424,913.64 274.6856,928.408 295.4536,919.24 L374.848,884.192 C381.5784,881.224 389.12,880.672 396.212,882.624 C432.696,892.656 471.5264,898 512,898 C737.332,898 912,732.944 912,510 C912,287.056 737.332,122 512,122 Z"
                          id="Path"
                        />
                        <path
                          d="M271.8016,623.4688 L389.3016,437.0528 C407.992,407.3968 448.016,400.0128 476.06,421.0448 L569.5136,491.1352 C578.088,497.5672 589.8856,497.5328 598.424,491.0528 L724.6376,395.2648 C741.484,382.4808 763.4736,402.6408 752.2,420.5312 L634.7,606.9488 C616.008,636.6032 575.984,643.9888 547.9416,622.9552 L454.4856,552.8632 C445.912,546.4328 434.1136,546.4672 425.576,552.9472 L299.3616,648.7352 C282.516,661.5184 260.5256,641.3584 271.8016,623.4688 Z"
                          id="Path"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>
                  {/* Masz kod promocyjny? */}
                  Rozpocznij czat
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
