import clsx from 'clsx';

export const HintMessage = ({ className, children, ...props }) => {
  return (
    <div
      className={clsx('mt-2 text-sm leading-6 text-gray-500', className)}
      {...props}
    >
      {children}
    </div>
  );
};
