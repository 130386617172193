import { gql, useMutation } from '@apollo/client';

export const NEWSLETTER_SUBSCRIBE_MUTATION = gql`
  mutation newsletterSubscribe(
    $email: String!
    $source: String!
    $metadata: JSONObject
  ) {
    newsletterSubscribe(email: $email, source: $source, metadata: $metadata)
  }
`;

export const NEWSLETTER_UNSUBSCRIBE_MUTATION = gql`
  mutation newsletterUnsubscribe($email: String!) {
    newsletterUnsubscribe(email: $email)
  }
`;

export function useNewsletter() {
  const [newsletterSubscribeMutation] = useMutation(
    NEWSLETTER_SUBSCRIBE_MUTATION
  );
  const [newsletterUnsubscribeMutation] = useMutation(
    NEWSLETTER_UNSUBSCRIBE_MUTATION
  );

  async function newsletterSubscribe(
    email: string,
    source: string,
    metadata?: any
  ) {
    return await newsletterSubscribeMutation({
      variables: {
        email,
        source,
        metadata,
      },
    });
  }

  async function newsletterUnsubscribe(email: string) {
    return await newsletterUnsubscribeMutation({
      variables: {
        email,
      },
    });
  }

  return {
    newsletterSubscribe,
    newsletterUnsubscribe,
  };
}
