import {
  FREE_SHIPPING_AMOUNT,
  SMALL_BOX_MIN_ORDER_AMOUNT,
} from 'shared/constants';
import { formatShortPrice } from 'shared/pricing';
import { TransformedText } from 'shared/ui/TransformedText';
import { getOrderUnder80Flow, useExperiment } from '../client/experiments';

export const FreeShippingHeaderItem = () => {
  return (
    <div className="flex flex-row items-center">
      <svg viewBox="0 0 31.9 16.7" className="fill-current h-4 mr-2">
        <path
          d="M10.3,13.4H4.7C4.3,13.4,4,13,4,12.6c0-0.4,0.4-0.8,0.8-0.8h5.6c0.4,0,0.8,0.4,0.8,0.8
C11.1,13,10.7,13.4,10.3,13.4"
        ></path>
        <path d="M0.8,7.9h8.7c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8H0.8C0.4,9.4,0,9,0,8.6C0,8.2,0.3,7.9,0.8,7.9"></path>
        <path d="M4.7,5.5C4.3,5.5,4,5.1,4,4.7c0-0.4,0.4-0.8,0.8-0.8h5.6c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8H4.7z"></path>
        <path
          d="M24.1,10.1c2.2-1.3,4.4-2.6,6.6-3.9l0,0.4l0,2.9l0,1.2v0.3c-1.2,0.7-2.3,1.4-3.5,2.1c-1,0.6-2.1,1.3-3.1,1.9
L24.1,10.1z M23,10.1l0,5.1c-0.1-0.1-0.3-0.1-0.4-0.2c-2.1-1.2-4.2-2.5-6.3-3.7l0-4.5l0-0.6C18.5,7.4,20.7,8.7,23,10.1z M23.5,9.1
C23.5,9.1,23.5,9.1,23.5,9.1c-0.3-0.2-0.6-0.4-0.9-0.6c-0.6-0.4-1.3-0.8-1.9-1.1C22.8,6,25,4.7,27.1,3.3c0.8,0.5,1.6,0.9,2.3,1.4
c0.3,0.2,0.5,0.3,0.8,0.5C28,6.5,25.8,7.8,23.5,9.1z M23.6,1.2c0.3,0.2,0.5,0.3,0.8,0.5c0.6,0.3,1.1,0.7,1.7,1
c-2.1,1.4-4.3,2.7-6.4,4.1c-0.9-0.6-1.9-1.1-2.8-1.7C17,5,17.2,4.9,17.4,4.8c1.7-1,3.4-1.9,5.1-2.9C22.8,1.6,23.2,1.4,23.6,1.2z
M31.8,8.4l0-3.3c0-0.6-1.4-1.1-1.7-1.3c-2.1-1.3-4.2-2.5-6.3-3.8c-0.1-0.1-0.3-0.1-0.5,0c-2.1,1.2-4.2,2.4-6.3,3.6
c-0.4,0.2-1.7,0.7-1.9,1.2c-0.3,1,0,2.3,0,3.3l0,3.4c0,0.6,1.3,1.1,1.7,1.3c2.1,1.3,4.3,2.5,6.4,3.8c0,0,0,0,0,0
c0.1,0.1,0.3,0.1,0.4,0c2.1-1.3,4.1-2.5,6.2-3.8c0.4-0.3,1.7-0.7,1.9-1.3C32.1,10.7,31.8,9.4,31.8,8.4z"
        ></path>
      </svg>
      <span>
        Darmowa dostawa od{' '}
        <strong>{formatShortPrice(FREE_SHIPPING_AMOUNT)}</strong>
      </span>
    </div>
  );
};

const MinOrderHeaderItem = () => {
  return (
    <div
      className="flex flex-row items-center"
      title={`Minimalna wartość zamówienia wynosi ${formatShortPrice(
        SMALL_BOX_MIN_ORDER_AMOUNT
      )} (​bez kosztów przesyłki).`}
    >
      <svg
        className="h-5 w-5 mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
      <span>
        Min. wartość koszyka to{' '}
        <strong>{formatShortPrice(SMALL_BOX_MIN_ORDER_AMOUNT)}</strong>
      </span>
    </div>
  );
};

export const postHeaderItems = [
  <FreeShippingHeaderItem />,
  <MinOrderHeaderItem />,
];

export function PostHeader() {
  const orderUnder80Flow = useExperiment(getOrderUnder80Flow, 'v1');

  return (
    <section className="md:hidden bg-orange-100 text-orange-700 h-12 flex items-center justify-center">
      <div>
        {orderUnder80Flow === 'v2' ? (
          <FreeShippingHeaderItem />
        ) : (
          <TransformedText items={postHeaderItems} />
        )}
      </div>
    </section>
  );
}
