import { forwardRef } from 'react';

export const EmailInput = forwardRef(
  ({ name, label, isInvalid, ...props }, ref) => {
    return (
      <>
        <label htmlFor={name} className="sr-only">
          {label}
        </label>
        <input
          ref={ref}
          id={name}
          name={name}
          type="email"
          autoComplete="email"
          autoCapitalize="off"
          autoCorrect="off"
          spellCheck="false"
          {...props}
        />
      </>
    );
  }
);
