import {
  isValidPhoneNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js';

export const postalCodePattern = {
  // W Polsce kody pocztowe mają format dd-ddd
  value: /^[0-9]{2}-[0-9]{3}$/,
  message: `Wprowadź poprawnie kod pocztowy (np. 01-151)`,
};

export const emailPattern = {
  value: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[A-Za-z]{2,8}$/,
  message: 'Wprowadź prawidłowy adres e-mail (np. example@gmail.com)',
};

export const phonePattern = {
  value: /^\+[1-9]\d{6,14}$/,
  message: 'Wprowadź poprawny numer telefonu (np. +48123456789)',
};

export function isPhoneNumberValid(phoneNumber) {
  let parsedPhoneNumber;
  try {
    parsedPhoneNumber = parsePhoneNumberWithError(phoneNumber);

    if (parsedPhoneNumber && !isValidPhoneNumber(parsedPhoneNumber.number)) {
      return false;
    }
  } catch (error) {
    return false;
  }

  return true;
}
